@import '../../variables.scss';
.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    &.list {
        float: right;
        margin-top: 15px;
    }
    &.add-modal {
        margin-right: 1rem;
    }
}

.project-background {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 2rem;
    .border-row {
        background-color: #F6F4F7;
    }
}

.edit-project {
    color: #fff;
    background-color: #009ADE;
    border-radius: 20px;
    text-align: center;
    padding: 7px 30px;
    right: 0;
    position: absolute;
    cursor: pointer;
}

.delete-project {
    border: 1px solid #D9141E;
    padding: 7px;
    border-radius: 30px;
    cursor: pointer;
    svg {
        fill: #D9141E;
        width: 20px;
        height: 20px;
    }
}

.icon-action {
    svg {
        width: 25px;
        height: 25px;
        color: #83899E !important;
        cursor: pointer;
    }
    img {
        width: 35px;
        margin: 4px 15px 0px 15px;
        cursor: pointer;
    }
    .trash {
        color: #d9141e !important
    }
}

.list-project .bx--tile--expandable {
    margin-bottom: 0rem;
}

.bx--tile {
    background-color: transparent !important;
}

.margin-list {
    padding: 10px;
}

//Modal
.button-select-color {
    background-color: #F6F8FB !important;
    border-bottom: 2px solid $light-blue !important;
    min-width: 100%;
    color: #161616 !important;
    justify-content: left !important;
}

.select-color {
    position: absolute;
    background: #fff;
    width: 95%;
    z-index: 2;
    -webkit-box-shadow: 4px 5px 8px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 4px 5px 8px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 4px 5px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 1rem;
    p {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
    }
    span,
    p {
        color: #83899E;
    }
}

.sketch-picker {
    display: block;
    margin: auto;
}

.circle-picker {
    width: 100% !important;
}

.center-button {
    justify-content: center !important;
    width: 100%;
    padding: 0px !important;
}

.button-select-color.form-control.is-invalid,
.button-select-color.form-control.is-valid {
    background-position: right calc(.375em + 2.1875rem) center !important;
    border-color: transparent !important;
    border-bottom: 2px solid $light-blue !important;
}

.disabled-color {
    background-color: #e4e4e4 !important;
}

.enabled-color {
    background-color: #F6F4F7 !important;
}

.subproject {
    .icon-action {
        margin-left: -11px;
    }
    .bx--tile-content__below-the-fold {
        margin-top: 0;
        background: #F6F4F7;
        display: block;
        padding: 1rem 0;
    }
    .bx--tile--expandable {
        max-height: fit-content !important;
    }
    .border-row {
        background-color: transparent !important;
    }
    .bx--tile-content__above-the-fold {
        background-color: #F6F4F7 !important;
    }
}

.project-background .subproject .border-row {
    background-color: transparent !important;
}

.bx--tile--is-expanded .bx--tile--is-expanded .bx--tile-content__below-the-fold {
    display: none !important;
}

.bx--tile--expandable .bx--tile--expandable .bx--tile__chevron svg {
    transform: rotate( -180deg);
    margin-left: 3.5rem;
}

.bx--tile--is-expanded .bx--tile--is-expanded .bx--tile__chevron svg {
    transform: none;
}

.arrow-icon {
    width: 25px;
    height: 25px;
    color: #83899E !important;
    margin-top: 14px;
}

///notebook
@media (max-width: 1366px) {
    .subproject {
        .dot.list {
            margin-right: -39px;
        }
        p {
            margin-left: 15px;
        }
    }
}