@import '../../../variables.scss';
.review {
    padding: 1.5rem !important;
    -webkit-box-shadow: 0px 17px 60px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0px 17px 60px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 17px 60px 0px rgba(0, 0, 0, 0.06);
    .title {
        font-weight: 500;
        outline: 2px solid $light-blue;
        font-size: 0.875rem;
        line-height: 1.29;
        width: 100%;
        padding: 0.4rem 1rem;
        background-color: $input-grey;
        margin-bottom: 1rem;
    }
}

.form-control {
    padding: 0rem .75rem !important;
}

.list-projects {
    padding: 0.4rem 0.6rem;
    border: 2px solid $light-blue;
    color: #1e2130;
    text-align: left;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 40px;
    white-space: nowrap;
    background-color: $input-grey;
}

.message {
    color: #d9141e;
    font-size: 0.9rem;
    svg {
        width: 15px;
        height: 15px;
        margin-bottom: 3px;
    }
    &.black {
        color: #161616;
    }
}

.input-group-text {
    padding: .375rem .45rem;
}

.modal-header {
    border-bottom: 0px !important;
}

// h3 {
//     font-weight: 600 !important;
//     margin-bottom: 1rem !important;
// }
.bx--number__controls {
    display: none !important;
}

.bx--number__control-btn {
    border-bottom: 0px !important;
}

.bx--progress-step svg {
    width: 100% !important;
    z-index: 0 !important
}

.bx--form-requirement {
    display: none !important;
}

.bx--number__input-wrapper {
    input {
        outline: 0px !important;
        font-family: 'Source Sans Pro', sans-serif !important;
        min-width: auto !important;
    }
    svg {
        display: none;
    }
}

.bx--progress-label {
    position: absolute;
    margin-top: 1.75rem !important;
    width: 100%;
    text-align: center;
    margin-right: 0 !important;
}

.bx--progress-optional {
    margin-top: 3rem !important;
    width: 100%;
    text-align: center !important;
    margin-left: 0px !important;
}

.buttons {
    margin-top: 3rem;
    .bx--btn .bx--btn__icon {
        right: 8rem;
        // width: 1.4rem;
        // height: 1.4rem;
    }
    .bx--btn {
        padding: calc(0.875rem - 3px) 8rem calc(0.875rem - 3px) 8rem;
        font-size: 16px;
    }
    button {
        min-height: 65px;
        max-width: 100%;
    }
}

h5 {
    margin: 1.5rem 0 1rem 0 !important;
}

.bx--progress {
    margin-top: 2rem;
}

.css-1y5ppqv-control:hover {
    border-color: transparent !important;
}

.css-1y5ppqv-control {
    box-shadow: 0 0 0 0px !important;
}

.button-previous {
    background-color: #f6f4f7 !important;
    color: $black !important;
    padding: calc(0.875rem - 3px) 1rem calc(0.875rem - 3px) 6rem !important;
    .bx--btn__icon {
        right: 10rem !important;
    }
}

.button-next {
    padding: calc(0.875rem - 3px) 3rem calc(0.875rem - 3px) 5rem !important;
    .bx--btn__icon {
        right: 3rem !important;
    }
}

.input-group {
    .input-group-text {
        // background-color: transparent !important;
        border: 0px !important;
        padding: 7px 0;
        display: block;
        background-color: #F6F8FB !important;
        border-bottom: 2px solid $light-blue !important;
        height: 2.5rem;
        padding-right: 5px;
    }
    .bx--form-item {
        width: 1%;
        .bx--number {
            position: relative;
            display: flex;
            flex-direction: column;
        }
    }
    .input-group-prepend {
        z-index: 0;
    }
}

.input-category.form-control.is-valid,
.coin-select.form-control.is-valid {
    background-position: right calc(.375em + 4.3rem) center !important;
}

.input-category.form-control.is-invalid,
.coin-select.form-control.is-invalid {
    background-position: right calc(.375em + 2.7rem) center !important;
}

.css-1wa3eu0-placeholder,
.css-1uccc91-singleValue {
    margin-left: 0px !important;
}

.bx--text-input,
.bx--number input[type='number'] {
    padding: 0 1rem !important;
}

.coin-select {
    margin-left: -15px;
}

.css-2b097c-container {
    // padding: 0 0.95rem !important;
    height: auto !important;
    padding: 0 !important;
}

// .css-1262e1v-ValueContainer {
//     margin-left: 1rem;
// }
// .input-category,
// .coin-select {
//     padding-left: 1rem !important;
// }
.btnSubmit {
    padding: 0 !important;
    text-align: center !important;
    display: block !important;
}

.progress-background {
    background-color: $white;
    height: 125px;
}

.bx--progress-step--incomplete .bx--progress-line {
    background-color: #fff !important;
}

.bx--progress-step--current .bx--progress-line,
.bx--progress-step--complete .bx--progress-line {
    background-color: $light-blue !important;
}

.bx--progress-step--current .bx--progress-label,
.bx--progress-step--current .bx--progress-optional,
.bx--progress-step--complete .bx--progress-label,
.bx--progress-step--complete .bx--progress-optional {
    color: $light-blue
}