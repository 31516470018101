@import '../../variables.scss';
// Header
.info-card p {
    margin-bottom: 0;
    font-size: 0.7rem;
}

.navbar .svg-inline--fa {
    width: 3.25em !important;
    height: 3em;
}

.navbar {
    display: block !important;
}

.center-div {
    display: flex;
    align-items: center;
}

.btn-secondary {
    color: #6c757d !important;
    background-color: transparent !important;
    border-radius: 0px !important;
}

.only-arrow {
    .bx--list-box__label {
        display: none;
    }
    .bx--list-box__menu-icon svg {
        height: 25px;
        width: 25px;
    }
    .bx--list-box__menu-icon {
        left: 10px;
    }
}

.bx--dropdown--inline:hover {
    background-color: transparent !important;
}

.bx--btn--primary {
    width: 100%;
    background-color: $light-blue !important;
}

// Page
.border-col-right {
    padding-left: 55px !important;
}

.bx--tile-content .border-row {
    margin-bottom: 0rem;
}

.cursor .border-row {
    cursor: -webkit-grab;
}

.list-service {
    .project {
        text-transform: capitalize;
    }
    .bx--label,
    .bx--list-box__label {
        color: #83899E;
    }
    .bx--list-box__menu-icon>svg {
        fill: #83899E;
    }
    .border-row {
        -webkit-box-shadow: 4px 5px 8px 0px rgba(0, 0, 0, 0.06);
        -moz-box-shadow: 4px 5px 8px 0px rgba(0, 0, 0, 0.06);
        box-shadow: 4px 5px 8px 0px rgba(0, 0, 0, 0.06);
    }
}

.icons img {
    height: 30px;
}

.service-project {
    padding: 3px 15px;
    border-radius: 25px;
    color: #fff;
    // text-transform: capitalize;
    margin-left: 20px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    max-width: 195px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.project-percentage {
    border: 1px solid;
    border-radius: 30px;
    padding: 3px;
    background: #fff;
    margin-left: -15px;
    margin-top: 20px;
    position: inherit;
}

.bx--overflow-menu__icon {
    width: 2.2rem !important;
    height: 2.2rem !important;
    fill: #83899E !important;
    // z-index: 2;
}

.table-title {
    font-size: 0.875rem;
    line-height: 0.7;
    color: #83899E;
    margin-top: 12px;
}

// List Project
h2 {
    font-weight: 600 !important;
}

.project-div {
    margin-top: 15px;
    color: #fff;
    border-radius: 30px;
    padding: 1.5rem;
    -webkit-box-shadow: 5px 5px 19px -3px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 5px 5px 19px -3px rgba(0, 0, 0, 0.38);
    box-shadow: 5px 5px 19px -3px rgba(0, 0, 0, 0.38);
    margin-bottom: 1.5rem;
    .row p {
        font-size: 13px;
    }
    .bx--tile {
        min-height: auto;
    }
    .bx--tile__chevron {
        right: 1px !important;
        margin-top: -20px !important;
        left: auto !important;
        svg {
            fill: #ffffff !important;
            width: 16px;
            height: 16px;
        }
    }
    .bx--tile--expandable {
        .bx--tile__chevron {
            right: 1px !important;
            margin-top: -20px !important;
            left: auto !important;
        }
    }
    .bx--tile--is-expanded .project-seemore:before {
        content: "Ver menos"
    }
    .project-seemore:before {
        content: "Ver más";
    }
}

.project-header {
    display: flex;
    flex-wrap: wrap;
}

.project-circle {
    background-color: #fff !important;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    margin-right: 1rem;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 19px;
    position: relative
}

.project-name {
    font-weight: 600 !important;
    font-size: 16px;
    // display: inline-block;
    position: relative;
    width: 79%;
}

.project-margin-amount {
    margin: 2.5rem 0;
    p {
        line-height: 1.2;
    }
}

.project-amount {
    font-weight: bold;
    font-size: 2.3rem;
    letter-spacing: 0.05em;
}

.project-coin {
    position: relative;
    top: -5px;
    margin-right: 5px;
}

.project-period {
    color: rgba(255, 255, 255, 0.65);
    font-size: 11px;
    margin-top: 7px;
}

.project-line {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 4px;
}

.project-number {
    font-size: 13px;
    line-height: 1.54;
    margin-top: 17px;
}

.project-seemore {
    float: right;
    margin-right: 22px;
}

.drag-drop-container {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.filter-select {
    width: auto !important;
    height: calc(1.5em + 0rem + 0px) !important;
    padding: 0rem .75rem !important;
    border: 0px !important;
}

.dropdown-item {
    text-transform: uppercase;
}

.fa-sortdate,
.fa-sortproject,
.fa-sortaccount {
    cursor: pointer
}

.fa-sortdate {
    margin: 1% 3%;
}

.ibm-min {
    height: 30px;
}

.bx--breadcrumb {
    margin-left: 5rem;
}

.bx--breadcrumb-item {
    text-transform: capitalize;
}

.bx--select-input {
    background-color: #F6F8FB !important;
    border-bottom: 2px solid $light-blue !important;
}

.border-row-son {
    background-color: #fff;
    // box-shadow: 4px 5px 8px 0px rgb(0 0 0 / 6%);
    border-bottom: 1px solid #DFE6E9;
    margin-left: 0.4rem !important;
}

.bx--tile {
    padding: 0 !important;
}

.bx--tile--expandable {
    overflow: initial !important;
    margin-bottom: 1rem;
}

.bx--tile--expandable:hover {
    background: transparent !important;
}

.title-expand {
    margin-left: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.resource-title {
    width: 100%;
    button {
        width: 100%;
    }
    .title-expand {
        color: $black
    }
}

.bx--tile__chevron {
    left: 0rem !important;
    top: 2.8em !important;
    height: 0rem !important;
    margin-top: 4px;
    svg {
        fill: #83899E !important;
        width: 22px;
        height: 22px;
    }
}

.bx--tile--is-expanded .bx--tile__chevron {
    margin-top: -1px;
}

.part-proyects {
    position: absolute;
    background-color: #fff;
    -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
    top: 3rem;
    width: 50%;
    z-index: 1;
    p {
        width: fit-content;
        display: inline-block;
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }
}

.btn-proyects {
    color: #ffffff;
    background-color: #5C75E3;
    padding: 6px 8px;
    border-radius: 25px;
    font-size: 1rem;
    height: 30px;
    svg {
        width: 20px;
        height: 20px;
    }
    &:hover {
        color: #FFF;
        text-decoration: none;
    }
}

.icon-expand {
    width: 25px;
    height: 25px;
    margin-top: 15px;
}

.bx--tile--is-expanded .bx--tile-content__below-the-fold {
    display: block !important;
    margin-top: 1rem;
}

.bx--tile-content__below-the-fold {
    display: none !important;
}

.bx--tile--clickable:focus,
.bx--tile--expandable:focus {
    outline: 0px !important;
}

///notebook
@media (max-width: 1366px) {
    .add-btn {
        width: auto;
    }
    .project-div {
        padding: 1.5rem 1rem;
    }
    .project-margin-amount p {
        font-size: 0.8rem;
    }
    .part-proyects {
        width: 100%;
    }
    .container.list.list-service {
        height: 77vh;
        overflow: auto;
    }
    .list-project {
        height: 77vh;
        overflow: auto;
    }
}

@media (max-width: 1920px) {
    .project-name {
        width:
         64% !important;
    }
    .container.list.list-service {
        height: 80vh;
        overflow: auto;
    }
    .list-project {
        height: 80vh;
        overflow: auto;
    }
}

.bx--tooltip__trigger {
    border: 0px !important
}

.bx--tooltip__trigger.bx--tooltip--bottom:focus svg,
.bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus {
    outline: 0px !important;
}

.bx--tooltip--definition .bx--tooltip__trigger {
    color: #83899E !important;
}