.account-list .border-row {
    -webkit-box-shadow: 4px 5px 8px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 4px 5px 8px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 4px 5px 8px 0px rgba(0, 0, 0, 0.06);
}

.bx--select-input {
    margin-bottom: 1rem;
}

.bx--select-input.form-control.is-invalid,
.bx--select-input.form-control.is-valid {
    background-position: right calc(.375em + 2.1875rem) center !important;
}

.date-form {
    background-color: #F6F8FB;
    border-bottom: 1px solid #5C75E3;
}

.bx--select__arrow {
    top: -6px !important;
}

.loading {
    margin: auto;
}

.file-upload.form-control {
    height: auto !important;
}

.file-upload.form-control.is-valid,
.file-upload.form-control.is-invalid {
    border-color: transparent;
}