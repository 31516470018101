@import '../../variables.scss';
.bx--side-nav__item {
    padding: 15px 0;
}

.bx--header__action--active {
    border-right: 0px !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
    +.logo {
        position: fixed;
    }
}

//custom
.logo {
    height: 23.2px;
    z-index: 2;
    margin-left: 4.5rem;
}

.breadcrum-custom {
    margin-left: -30px;
}

.logout {
    position: absolute;
    right: 30px;
    cursor: pointer;
    svg {
        fill: #fff;
        width: 30px;
        height: 30px;
        background-color: $light-blue;
        border-radius: 20px;
        margin-top: -2px;
        margin-left: 9px;
        padding: 5px;
    }
}

.notification {
    right: 70px;
}

.notifications {
    position: absolute;
    right: 31px;
    margin-top: 20em !important;
    z-index: inherit;
    background: #ffff;
    box-shadow: 4px 2px 4px -1px rgba(0, 0, 0, 0.1);
    .bx--inline-notification--info {
        background: #fff !important;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        -webkit-box-shadow: 4px 2px 4px -1px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 4px 2px 4px -1px rgba(0, 0, 0, 0.08);
        box-shadow: 4px 2px 4px -1px rgba(0, 0, 0, 0.08);
        border-left: 3px solid #4e76e7;
        .bx--inline-notification__details {
            color: #161616;
        }
        .bx--inline-notification__icon {
            fill: #4e76e7;
        }
    }
    .bx--inline-notification__action-button {
        color: #0091ff !important;
        &.bx--btn--ghost:hover {
            background-color: #4c4c4c08;
        }
    }
}

.number-notif {
    background-color: #fb9785;
    z-index: 2;
    padding: 2px;
    font-size: fit-content(10px);
    width: fit-content(35px);
    height: fit-content(35px);
    border-radius: 20px;
    margin-left: -15px;
    padding: 1px 5px;
    color: #fff;
    position: absolute;
    margin-top: 15px;
}