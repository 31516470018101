//colors
$black: #191c2c;
$grey:#83899e;
$calendar-grey: #828aa0;
$checkbox-grey: #959bae;
$filter-grey: #4a4a4a;
$light-grey: #f6f4f7;
$input-grey: #f6f8fb;
$light-blue: #0091ff;
$line-blue: #1166f8;
$filter-light-blue: #4e76e7;
$orange:#fa6400;
$white: #eff3fb;
$margin-48: 48px;