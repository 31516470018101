@import '../../../variables.scss';

.group {
    width: 1440px;
    height: 1024px;
    object-fit: contain;
}

.login-container {
    background: url(../../../assets/img/background.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
}

.logo-login {
    width: 244px;
    height: 38px;
    margin: 52px auto;
    object-fit: contain;
    display: block;
}

.text {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 48px;
}

.blue-dot {
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background: #0091ff;
    position: absolute;
    right: 0;
    bottom: 0;
}

.footer {
    position: absolute;
    bottom: 23px;
    width: 100%;
    p {
        font-size: 13px;
        text-align: right;
        color: $white;
    }
}

.back-login {
    // height: 540px;
    margin: 0px auto 100px auto;
    padding: 60px 48px !important;
    border-radius: 16px;
    background-color: #fff;
    -webkit-box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.06);
    h3 {
        color: #83899e;
        text-align: center;
        margin-bottom: 6rem !important;
        svg {
            margin-top: -3px;
            margin-right: 5px;
        }
    }
    p {
        // text-align: right;
        // color: #83899e;
        // padding-bottom: 2rem;
        letter-spacing: -0.49px;
        text-align: center !important;
        color: $light-blue;
    }
    button {
        display: block;
        margin: 48px auto !important;
        width: 200px;
        text-align: center;
        padding: 0;
        height: 60px;
        font-size: 20px;
        // width: auto;
        // padding: calc(0.875rem - 3px) 30px;
        // display: block;
        // margin: auto;
    }
}

@media (max-width: 1633px) {
    // .back-login {
    //     margin: 4em auto 5em auto;
    // }
    .blue-dot {
        bottom: 20px;
    }
}

.margin-bottom {
    margin-bottom: 48px;
}

.back-icon {
    margin-top: -20px;
    margin-left: -18px;
}