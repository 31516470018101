@import '../../variables';
.border-row {
    margin-bottom: 1rem;
    background-color: #fff;
}

.list {
    .row {
        margin-left: -5px;
        margin-right: -5px;
    }
    p {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.div-contents {
    display: contents;
}

// ListService
@media (min-width: 1200px) {
    .container {
        max-width: 100% !important;
    }
}

.margin-without-icon button {
    text-align: center;
    width: 100%;
    padding: 0px !important;
    display: block;
}

.modal-button .button-previous {
    padding: calc(0.875rem - 3px) 1rem calc(0.875rem - 3px) 12rem !important;
    .bx--btn__icon {
        right: 18rem !important;
    }
}

textarea.form-control.is-invalid,
textarea.form-control.is-valid {
    padding: 0.6875rem 1rem !important;
}

.bx--inline-loading {
    margin-left: 10rem;
    margin-top: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}

.password-message {
    color: #83899e;
    padding-bottom: 1rem;
    display: block;
    margin-top: -10px;
}

.swal2-title,
.swal2-html-container {
    color: $black;
}

.swal2-popup {
    border-radius: 16px;
}

.swal2-styled.swal2-confirm {
    background-color: $light-blue;
    border-radius: 0;
    font-size: 20px;
    margin-top: 48px;
}

.swal2-styled.swal2-cancel {
    background-color: #757575;
    border-radius: 0;
    font-size: 20px;
    margin-top: 48px;
}

.swal2-image {
    width: 70px;
    height: 70px;
    padding: 2px 1px 1px 2px;
    border-radius: 35px;
    margin-bottom: 40px;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
    background: #1166f8cc !important;
}

.swal2-footer {
    border-top: 0px;
    a {
        color: $light-blue
    }
}

.image-success {
    background-color: #d6eee1;
}

.image-error {
    background-color: #ff7979;
    padding: 12px 1px 12px 2px;
}

.image-size {
    padding: 12px 1px 12px 2px !important;
}

.bx--number {
    width: 100%;
}

.bx--text-input,
.bx--text-area,
.bx--number input[type='number'] {
    margin-bottom: 1rem;
    background-color: $input-grey !important;
    border-bottom: 2px solid $light-blue !important;
    padding-right: 0rem !important;
    border-radius: 0 !important;
    height: 40px;
}

.bx--text-input:focus,
.bx--text-input:active,
.bx--text-area:focus,
.bx--text-area:active,
.bx--select-input:focus {
    outline: 2px solid $light-blue !important;
}

.modal-backdrop {
    background-color: $line-blue !important;
}

.modal-backdrop.show {
    opacity: 0.5 !important;
}

.close {
    text-shadow: none !important;
    opacity: 1 !important;
    span {
        background-color: $light-blue;
        border-radius: 20px;
        color: #fff;
        padding: 0px 7px;
        font-size: 17px;
    }
}

.logged p {
    color: #83899e
}

.logged p:first-child {
    margin-left: 4.5rem;
}

.custom-control {
    margin-bottom: 10px;
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #eff3fb;
    // position: relative;
    // margin-top: 15% !important;
    height: 35px;
    // clear: both;
    // margin-bottom: 17px;
    p {
        font-size: 13px;
        text-align: right;
        color: $white;
    }
}

body:before {
    content: "";
    height: 100%;
    float: left;
    width: 0;
}

.capitalize {
    text-transform: capitalize;
}

#role_id {
    text-transform: capitalize;
}

body.modal-open #root {
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
}