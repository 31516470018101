@import '../../../variables.scss';
.chart-backgroud {
    background: white;
    margin: 1rem;
    position: inherit;
    padding: 2rem;
}

.bx--cc--chart-svg {
    box-sizing: border-box !important;
}


text {
    font-family: 'Source Sans Pro', sans-serif !important;
}

.filter-buttons {
    padding: 3px 0 !important;
    min-height: 20px !important;
    background: transparent !important;
    color: $light-blue !important;
    margin: 1rem 0 1rem 0 !important;
    .borders {
        border: 2px solid;
        border-radius: 8px;
    }
}

.dashboard {
    .bx--tabs--scrollable {
        float: right;
        margin-top: -62px;
        width: 35%;
        .bx--tabs--scrollable__nav-link {
            border-bottom: 0px;
        }
    }
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link,
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link:focus,
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link:active {
        border-bottom: 0px;
        overflow: visible
    }
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link:focus,
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link:active {
        outline: 0px
    }
    .filters-background {
        background: #fff;
        height: fit-content;
    }
    h5 {
        margin: 1rem 0 0.5rem 0 !important;
    }
    .bx--tile__chevron {
        right: 1px !important;
        margin-top: 10px !important;
        left: auto !important;
    }
    .bx--checkbox-label::before {
        border: 1px solid $checkbox-grey;
    }
    .bx--checkbox-label-text {
        color: $checkbox-grey
    }
    .bx--checkbox:checked+.bx--checkbox-label::before,
    .bx--checkbox:indeterminate+.bx--checkbox-label::before,
    .bx--checkbox-label[data-contained-checkbox-state='true']::before,
    .bx--checkbox-label[data-contained-checkbox-state='mixed']::before {
        background-color: $filter-light-blue;
        border-color: $filter-light-blue;
    }
    .bx--checkbox:checked+.bx--checkbox-label .bx--checkbox-label-text {
        color: $filter-light-blue !important;
    }
    .bx--tile--is-expanded .bx--tile-content__below-the-fold {
        margin-top: 0;
    }
    .bx--fieldset {
        margin-bottom: 0;
    }
    .bx--tile-content__above-the-fold {
        border-top: 2px solid #959bae52;
    }
    .calendar-label {
        font-size: 10px;
        margin-bottom: 7px;
    }
    .react-datepicker__input-container input {
        width: 100%;
        height: 40px;
        padding: 11px 10px;
        border: solid 2px $filter-light-blue;
    }
    .icon-calendar {
        fill: $calendar-grey;
        width: 20px;
        height: 20px;
        margin-top: 2rem;
    }
    .hide-filter {
        margin-top: 1.5rem;
        font-size: 13px;
        text-align: right;
        color: $filter-grey;
        svg {
            fill: $filter-grey;
            width: 16px;
        }
    }
    .bx--tile--expandable {
        margin-bottom: 0;
    }
    .content-box {
        padding: 5px;
        p {
            font-size: 12px;
            padding: 0 8px;
        }
    }
    .y-labels {
        display: none
    }
    .bx--radio-button-group {
        display: block;
    }
    .bx--radio-button-wrapper {
        padding: 0.25rem 0;
    }
    .bx--radio-button__appearance {
        border: 1px solid $checkbox-grey;
    }
    .bx--radio-button__label {
        color: $checkbox-grey
    }
    .bx--radio-button:checked+.bx--radio-button__label .bx--radio-button__appearance::before {
        background-color: $filter-light-blue;
    }
    .bx--radio-button:checked+.bx--radio-button__label .bx--radio-button__appearance {
        border-color: $filter-light-blue;
    }
    .bx--radio-button:checked+.bx--radio-button__label {
        color: $filter-light-blue !important;
    }

    .white-background {
        background: #fff;
        padding: 1rem;
        border-radius: 12px;
        margin: 0px 0px 24px 0px;
    }
    .currency {
        width: 48px;
        height: 48px;
        padding: 12px;
        border-radius: 25px;
        margin-top: 5px;
        &.npp-total {
            background-color: #f3e0e0;
            fill: #fb9785
        }
        &.total {
            background-color: #cfe0fe;
            fill: #1166f8
        }
        &.byproject {
            background-color: #eaf9fb;
            fill: #38bfc3
        }
    }
    .color-border {
        border: 1px solid #fb978559;
    }
    .numbers {
        span {
            font-size: 1rem;
        }
        h5 {
            margin: 0px !important;
            display: contents;
        }
    }
    .see-all {
        font-size: 11px;
        color: #828aa0;
        margin-top: 15px;
        cursor: pointer;
        text-align: right;
        svg {
            width: 16px;
        }
    }
    thead tr th {
        border-top: 0px !important;
        color: #828aa0;
        border-bottom: 1px solid #828aa05c
    }
    .table-striped tbody tr:nth-of-type(odd) {
        background-color: #eff3fb;
    }
    .table td {
        border-top: 0px !important;
    }
    
    .table-title {
        color: #171717;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 24px;
    }
    .table td,
    .table th {
        font-size: 13px;
    }
    .table {
        border-bottom: 1px solid #828aa05c
    }
    .tag-project {
        padding: 3px 15px;
        border-radius: 25px;
        color: #fff;
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .project-percentage.main {
        margin-top: 8px !important;
        position: absolute !important;
    }
    .tag-more {
        color: #828aa0;
        cursor: pointer;
        text-decoration: none;
        svg {
            width: 18px;
            height: 18px;
        }
    }
    .tag-show {
        position: absolute;
        background: white;
        background-color: #fff;
        width: 170px;
        -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
        z-index: 1;
        margin-top: 8px;
        .tag-project {
            width: fit-content;
            display: inline-block;
            margin: 8px !important;
        }
    }
    .project-percentage {
        font-size: 10px;
    }
    .bx--radio-button-wrapper .bx--radio-button__label {
        justify-content: left;
    }
    .custom-bar {
        .bx--cc--chart-svg .bar {
            fill: #1166f8 !important;
        }
        g.bx--cc--legend g.legend-item rect.checkbox {
            fill: #1166f8 !important;
        }
    }
    .bx--chart-holder {
        padding: 1rem;
        border-radius: 12px;
        margin-bottom: 0px;
        background-color: #fff !important;
        h5 {
            margin-top: 0px !important;
        }
    }
    .bx--cc--toolbar{
        display: none;
    }
    .bx--cc--chart-wrapper{
        border-radius: 12px;
        background-color: #fff !important;
    }
}

.perday-style{
    div.bx--cc--legend {
        display:none !important;
     }
}