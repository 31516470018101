@import '../../../variables.scss';
.react-datepicker-wrapper {
    width: 100%;
}

.content-datepicker {
    width: 100%;
}

.date-picker-custom {
    display: flex;
    padding: 0.8rem 0.75rem;
    font-size: 13px;
    border-bottom: 2px solid $light-blue;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: #FFF;
    min-height: 40px;
    width: 100%;
    text-transform: capitalize;
    color: $calendar-grey;
    svg {
        width: 15px;
        height: 15px;
        margin-top: -2px;
        right: 8px;
        position: absolute;
    }
}

.react-datepicker {
    border: 0px !important;
    border-radius: 0px !important;
}

.react-datepicker__header {
    background-color: #fff !important;
    border-bottom: 0px !important;
    border-top-left-radius: 0rem !important;
    padding: 15px 0 !important;
    font-weight: normal !important;
}

.react-datepicker__month .react-datepicker__month-text {
    width: 2rem !important;
    margin: 10px !important;
    text-transform: capitalize;
}

.react-datepicker__month--in-range {
    background-color: transparent !important;
    color: #5C75E3 !important;
}

.react-datepicker__month-text--keyboard-selected {
    background-color: transparent !important;
    color: #5C75E3 !important;
    display: inline-block !important;
    position: relative !important;
}

.react-datepicker__month-text--keyboard-selected::before {
    transform: translateX(-50%);
    position: absolute;
    background: #5C75E3;
    bottom: -10px;
    height: 5px;
    content: '';
    width: 5px;
    left: 50%;
}

.react-datepicker__month--range-start::before {
    position: absolute;
    background: #5C75E3;
    height: 5px;
    content: '';
    width: 5px;
    margin-left: 9px;
    margin-top: 16px;
}

.react-datepicker__month--range-end::before {
    margin-left: 0px !important;
}

.react-datepicker__month .react-datepicker__month-text:focus {
    outline: -webkit-focus-ring-color auto 0px !important;
}