@import '~carbon-components/scss/globals/scss/styles.scss';
@import 'variables';
body {
    font-family: 'Source Sans Pro', sans-serif;
    background-color: $white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

h1,
h2 {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: bold;
    color: $black
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 24px;
}

h3,
h4 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    color: $black
}

h3 {
    font-weight: 600;
}

h4 {
    font-weight: 400;
}

.bx--header {
    position: unset;
    margin: 1rem 0;
    background-color: transparent;
    border-bottom: 0;
}

.bx--header__menu-trigger>svg {
    fill: #393939;
}

.bx--header__name {
    color: #83899E !important;
}

.bx--header__menu-trigger:hover {
    fill: transparent;
}

.bx--header__action:hover {
    background-color: transparent;
}

.bx--header__action:active,
a.bx--header__menu-item:active {
    color: #393939;
    background-color: transparent;
}

.bx--header__menu-toggle {
    z-index: 2;
    position: fixed;
}

.bx--side-nav__navigation {
    margin-top: -4rem;
    z-index: 1;
    height: auto;
}

.bx--side-nav--expanded {
    padding: 0 15px;
    width: 14rem;
    margin-top: -4rem;
    z-index: 1;
}

.bx--side-nav--expanded,
.bx--side-nav.bx--side-nav--rail:not(.bx--side-nav--fixed):hover {
    width: 15rem;
}

.bx--side-nav--ux .bx--side-nav__items {
    margin-top: 5rem;
}

.bx--side-nav a.bx--header__menu-item:focus,
a.bx--side-nav__link:focus {
    outline: 0;
}

.bx--side-nav__link-text {
    font-weight: 400;
    color: #83899E;
}

.btn-menu {
    background-color: #171717;
    color: #fff;
    svg {
        fill: #fff;
    }
    .bx--side-nav__link-text {
        color: #fff !important;
    }
}

.bx--btn--primary {
    min-height: 40px;
    background-color: $light-blue;
}

.bx--btn--primary:focus {
    border-color: $light-blue !important;
    box-shadow: inset 0 0 0 1px $light-blue, inset 0 0 0 2px #ffffff !important;
}

.bx--list-box__menu-icon {
    top: 8px
}

.bx--dropdown__wrapper--inline {
    grid-gap: 0rem;
    display: flex;
}

.bx--dropdown--inline {
    max-width: 90%;
}